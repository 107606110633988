import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FetchChatRoomStateI {
    isNewSession: boolean
};

const initialState: FetchChatRoomStateI = {
    isNewSession: false,
};

export const fetchChatRoomSlice = createSlice({
    name: 'fetch-chat-room',
    initialState,
    reducers: {
        setIsNewSession: (state, action: PayloadAction<boolean>) => {
            state.isNewSession = action.payload;
        },
    },
})

export const { setIsNewSession } = fetchChatRoomSlice.actions;
export default fetchChatRoomSlice.reducer;