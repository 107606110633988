import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";

interface PasswordFieldI {
    password: string,
    handlePasswordChange: (value: string) => void
}

const PasswordField = (props: PasswordFieldI) => {

    const { password, handlePasswordChange } = props;

    const [passwordFieldType, setPasswordFieldType] = useState<'password' | 'text'>('password');

    return (
        <div className="relative">
            <input
                type={passwordFieldType}
                className='w-full rounded-md border-0 py-1.5 pr-16 text-slate-400 ring-1 ring-inset ring-gray-200 truncate placeholder:text-gray-400 sm:text-sm sm:leading-6 resize-none pt-3 focus:ring-primary-color'
                value={password}
                onChange={(e) => { handlePasswordChange(e.target.value) }}
            />
            {passwordFieldType === 'password' ?
                <EyeOff className="absolute top-1/2 -translate-y-1/2 right-2 text-gray-400 hover:cursor-pointer" onClick={(e) => setPasswordFieldType('text')} />
                :
                <Eye className="absolute top-1/2 -translate-y-1/2 right-2 text-gray-400 hover:cursor-pointer" onClick={(e) => setPasswordFieldType('password')} />
            }
        </div>
    );
}

export default PasswordField;