import { UserRolesEnum } from "../../enums/userRoles";
import { TabI } from "../../types/ui/tabs";

export const COLLECTION_DOCS_TABS: TabI[] = [
  { id: 0, name: 'In Collection', current: true },
  { id: 1, name: 'All', current: false },
];

export const DEFAULT_NEW_COLLECTION_NAME = 'New Collection';
export const DEFAULT_COLLECTION_NAME = 'Untitled Collection';

export const DUMMY_CONTENT_TAGS = ['Web Development Components', 'Software Engineering Components', 'HTTP Communication'];

export const COLLECTIONS_USER_ROLES_MAP: Record<number, `${UserRolesEnum}`> = {
  0: UserRolesEnum.SUBSCRIBER,
  1: UserRolesEnum.ADMIN,
  4: UserRolesEnum.ORG_ADMIN
};