import { StepI } from "../../types/ui/progressBar"

interface ProgressBarI {
    steps: StepI[]
}

export const ProgressBar = (props: ProgressBarI) => {

    const { steps } = props;

    return (
        <nav aria-label="Progress">
            <ol className="flex justify-start w-full gap-4">
                {steps.map((step) => (
                    <li key={step.id} className="flex">
                        {step.status === 'complete' || step.status === 'current' ? (
                            <div className="bg-primary-color sm:w-[54px] w-8 h-2 rounded-lg"></div>
                        ) : (
                            <div className="bg-gray-300 sm:w-[54px] w-8 h-2 rounded-lg"></div>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}