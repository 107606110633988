import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AgentEchoStateI {
    isSaveModalOpen: boolean,
    isFilterModalOpen: boolean
};

const initialState: AgentEchoStateI = {
    isSaveModalOpen: false,
    isFilterModalOpen: false
};

export const agentEchoSlice = createSlice({
    name: 'agent-echo',
    initialState,
    reducers: {
        setIsSaveModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isSaveModalOpen = action.payload;
        },
        setIsFilterModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isFilterModalOpen = action.payload;
        },
        clearAgentEchoState: (state) => {
            state.isSaveModalOpen = false;
            state.isFilterModalOpen = false;
        },
    },
})

export const { setIsSaveModalOpen, setIsFilterModalOpen, clearAgentEchoState } = agentEchoSlice.actions;
export default agentEchoSlice.reducer;