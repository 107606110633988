
import React from 'react';
import styles from './Avatar.module.css';
import { classNames } from '../../utils/styleHelpers';
import { OrganizationAvatarDetailsI } from '../../types/ui/avatars';

interface OrganizationAvatarI {
    orgAvatarDetails: OrganizationAvatarDetailsI,
    avatarClassName?: string,
    initialsClassName?: string
}

const OrganizationAvatar = (props: OrganizationAvatarI) => {

    const { orgAvatarDetails, avatarClassName = '', initialsClassName = '' } = props;

    return (
        <div className={classNames(`${styles.avatar}`, avatarClassName)}>
            {orgAvatarDetails.avatar
                ? <img src={orgAvatarDetails.avatar} alt="Org Avatar" className={styles.avatarImage} />
                : <div className={classNames(`${styles.initials}`, initialsClassName)}>{orgAvatarDetails.name.charAt(0).toUpperCase()}</div>
            }
        </div>
    );
};

export default OrganizationAvatar;
