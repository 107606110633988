import axios from "axios";
import { getAmplifySessionHeaders } from "../auth-service";
import { API_URL } from "../../config";

export const uploadOrgLogo = async (file: File) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${API_URL}/orgs/logo/`, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error('Error uploading organization logo:', error);
    }
};

export const uploadOnboardingOrgLogo = async (inviteId: string, file: File) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('invite_id', inviteId);

    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${API_URL}/orgs/onboarding/logo/`, formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error('Error uploading onboarding organization logo:', error);
    }
};

export const updateOrg = async (name: string) => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${API_URL}/orgs/update/`, { name }, { headers });
        return response;
    } catch (error) {
        console.error('Error updating organization:', error);
    }
}

export const updateOnboardingOrg = async (inviteId: string, name: string) => {
    try {
        const headers = await getAmplifySessionHeaders();
        const response = await axios.post(`${API_URL}/orgs/onboarding/update/`, { invite_id: inviteId, name }, { headers });
        return response;
    } catch (error) {
        console.error('Error updating onboarding organization:', error);
    }
}