// fetchKnowledgeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UploadingFile {
    fileName: string;
    documentId: string | null;
    status: 'uploading' | 'processing';
}

interface FetchKnowledgeStateI {
    isUploadModalOpen: boolean;
    isContentMenuOpen: boolean;
    isAddNewCategoryBtnClicked: boolean;
    titleOfContentCreating: string | undefined;
    contentEditing: { document_id: string; title: string } | undefined;
    uploadingFiles: UploadingFile[]; // New state
};

const initialState: FetchKnowledgeStateI = {
    isUploadModalOpen: false,
    isContentMenuOpen: false,
    isAddNewCategoryBtnClicked: false,
    titleOfContentCreating: undefined,
    contentEditing: undefined,
    uploadingFiles: [], // Initialize as empty
};

export const fetchKnowledgeSlice = createSlice({
    name: 'fetch-knowledge',
    initialState,
    reducers: {
        setIsUploadModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUploadModalOpen = action.payload;
        },
        setIsContentMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isContentMenuOpen = action.payload;
        },
        setIsAddNewCategoryBtnClicked: (state, action: PayloadAction<boolean>) => {
            state.isAddNewCategoryBtnClicked = action.payload;
        },
        setTitleOfContentCreating: (state, action: PayloadAction<string | undefined>) => {
            state.titleOfContentCreating = action.payload;
        },
        setContentEditing: (state, action: PayloadAction<{ document_id: string, title: string } | undefined>) => {
            state.contentEditing = action.payload;
        },
        clearFetchKnowledgeState: (state) => {
            state.isUploadModalOpen = false;
            state.isContentMenuOpen = false;
            state.isAddNewCategoryBtnClicked = false;
            state.titleOfContentCreating = undefined;
            state.contentEditing = undefined;
            state.uploadingFiles = []; // Clear uploadingFiles on reset
        },
        addUploadingFile: (state, action: PayloadAction<UploadingFile>) => {
            // Prevent duplicates
            if (!state.uploadingFiles.some(file => file.fileName === action.payload.fileName && file.documentId === action.payload.documentId)) {
                state.uploadingFiles.push(action.payload);
            }
        },
        updateUploadingFile: (state, action: PayloadAction<{ fileName: string; documentId: string | null; status: 'uploading' | 'processing' }>) => {
            const index = state.uploadingFiles.findIndex(file => file.fileName === action.payload.fileName && file.documentId === action.payload.documentId);
            if (index !== -1) {
                state.uploadingFiles[index].status = action.payload.status;
                if (action.payload.documentId) {
                    state.uploadingFiles[index].documentId = action.payload.documentId;
                }
            }
        },
        removeUploadingFile: (state, action: PayloadAction<{ fileName: string; documentId: string | null }>) => {
            state.uploadingFiles = state.uploadingFiles.filter(file => !(file.fileName === action.payload.fileName && file.documentId === action.payload.documentId));
        },
    },
})

export const { 
    setIsUploadModalOpen, 
    setIsContentMenuOpen, 
    setIsAddNewCategoryBtnClicked, 
    setTitleOfContentCreating, 
    setContentEditing, 
    clearFetchKnowledgeState,
    addUploadingFile,
    updateUploadingFile,
    removeUploadingFile,
} = fetchKnowledgeSlice.actions;

export default fetchKnowledgeSlice.reducer;