import { Contact, Mail } from 'lucide-react';

interface InviteExpiredI {
    orgLogo: string
}

const InviteExpired = (props: InviteExpiredI) => {

    const { orgLogo } = props;

    return (
        <div className='flex w-full h-svh justify-center items-center bg-slate-100'>
            <div className='flex flex-col p-10 max-lg:mx-10 lg:w-1/2 bg-white shadow-lg rounded-xl gap-10'>
                <div className='flex flex-col items-center justify-center gap-4'>
                    <img src={orgLogo} alt='logo' className='sm:h-16 h-12' />
                    <div className='text-center'>
                        <span className='text-slate-600'>We're sorry, but the invitation link you've clicked </span>
                        <span className='text-rose-500'>has expired</span>
                    </div>
                    <div className='text-base font-normal text-slate-400 text-center'>
                        To gain access, please take one of the following steps
                    </div>
                </div>
                <div className='flex justify-center gap-5 flex-wrap'>
                    <div className='flex flex-col items-center gap-2 p-4 border rounded-lg grow-0 w-[200px]'>
                        <div className='bg-yellow-50 p-2 mr-auto rounded-lg'>
                            <Contact className='text-yellow-500' />
                        </div>
                        <div className='text-sm font-normal text-slate-400'>
                            Contact the person who invited you to request a new invitation
                        </div>
                    </div>
                    <div className='flex flex-col items-center gap-2 p-4 border rounded-lg grow-0 w-[200px]'>
                        <div className='bg-lighter-primary-color p-2 mr-auto rounded-lg'>
                            <Mail className='text-primary-color' />
                        </div>
                        <div className='text-sm font-normal text-slate-400'>
                            Reach out to our support team at <span className='text-primary-color'>support@senso.ai</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InviteExpired;