interface LeftComponentI {
    handleGotoNextStep: () => void
}

const LeftComponent = (props: LeftComponentI) => {

    const { handleGotoNextStep } = props;

    return (
        <div className="flex flex-col gap-8 lg:mt-auto mt-10">
            <div className='flex flex-col gap-2'>
                <div className='font-medium text-3xl text-slate-600'>Welcome to Senso!</div>
                <div className='text-base font-normal text-slate-400'>The Senso platform empowers you to optimize how you operate, collaborate, and generate business impact.</div>
            </div>
            <div className="flex flex-col gap-8">
                <button
                    className="text-white text-sm font-medium bg-primary-color"
                    onClick={() => handleGotoNextStep()}
                >
                    Get Started
                </button>
                <div className="text-gray-400 text-xs">
                    By clicking 'Get Started' to join Senso, you agree to Senso's <a href="https://senso.ai/terms-of-service" target='_blank' className="text-primary-color" rel="noreferrer">Terms of Use</a> and <a href="https://senso.ai/privacy-policy" target='_blank' className="text-primary-color" rel="noreferrer">Privacy Policy</a>
                </div>
            </div>
        </div>
    );
}

export default LeftComponent;