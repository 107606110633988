import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationI } from '../../../types/organization';

interface OrganizationStateI {
    selectedOrganization?: OrganizationI,
    allOrganizations: OrganizationI[]
};

const initialState: OrganizationStateI = {
    allOrganizations: []
};

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setSelectedOrganization: (state, action: PayloadAction<OrganizationI>) => {
            state.selectedOrganization = action.payload;
        },
        setAllOrganizations: (state, action: PayloadAction<OrganizationI[]>) => {
            state.allOrganizations = action.payload;
        },
        clearOrganizationState: (state) => {
            state.selectedOrganization = undefined;
            state.allOrganizations = [];
        }
    },
})

export const { setSelectedOrganization, setAllOrganizations, clearOrganizationState } = organizationSlice.actions;
export default organizationSlice.reducer;