import { FilePen, Upload } from "lucide-react";
import { AdvancedMenuItemI } from "../../types/ui/menus";

export const ADD_CONTENT_MENU_ITEMS: AdvancedMenuItemI[] = [
    {
        iconConfig: {
            icon: Upload,
            iconColorClass: 'text-[#0F6D54]',
            bgColorClass: 'bg-[#EEFBF5]',
            borderColorClass: 'border-[#D5F6E6]'
        },
        text: 'Upload Content',
        onClick: () => { }
    },
    {
        iconConfig: {
            icon: FilePen,
            iconColorClass: 'text-[#0F6D54]',
            bgColorClass: 'bg-[#EEFBF5]',
            borderColorClass: 'border-[#D5F6E6]'
        },
        text: 'Create Content',
        onClick: () => { }
    }
];
