import { headers } from "../config";
import { Auth } from "aws-amplify";

export const setAuthToken = (token, refreshToken, expiry) => {
  headers.Authorization = `Bearer ${token}`;
  localStorage.setItem("_tk", token);
  localStorage.setItem("_refreshTk", refreshToken);
  localStorage.setItem("_tkExpiry", expiry);
};

export async function getAmplifySessionHeaders() {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken().getJwtToken();

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  };
}

export const getAccessToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const signIn = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);

    const signInUserSession = user.signInUserSession;
    const idToken = signInUserSession.idToken;
    const refreshToken = signInUserSession.refreshToken;
    const token = idToken.jwtToken;
    const expiry = idToken.getExpiration();

    setAuthToken(token, refreshToken.token, expiry);
    return user;
  } catch (error) {
    console.log('Error signing in:', error);
  }
};