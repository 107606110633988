import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModuleType, ToolType } from '../../../types/app';
import { ModuleEnum } from '../../../enums/app';

interface AppStateI {
    currentModule: ModuleType,
    currentTool?: ToolType
};

const initialState: AppStateI = {
    currentModule: ModuleEnum.FETCH
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCurrentModule: (state, action: PayloadAction<ModuleType>) => {
            state.currentModule = action.payload;
        },
        setCurrentTool: (state, action: PayloadAction<ToolType>) => {
            state.currentTool = action.payload;
        },
    },
})

export const { setCurrentModule, setCurrentTool } = appSlice.actions;
export default appSlice.reducer;