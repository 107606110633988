import React, { RefObject, useEffect, useRef, useState } from 'react';

interface ConfirmationCodeInputI {
    inputSizeClass?: string,
    setConfirmationCode: (confirmationCode: string) => void,
    setIsValidConfirmationCode: (isValid: boolean) => void
}

const ConfirmationCodeInput = (props: ConfirmationCodeInputI) => {

    const { inputSizeClass = 'size-12 max-sm:size-10', setConfirmationCode, setIsValidConfirmationCode } = props;

    const [code1, setCode1] = useState<string>('');
    const [code2, setCode2] = useState<string>('');
    const [code3, setCode3] = useState<string>('');
    const [code4, setCode4] = useState<string>('');
    const [code5, setCode5] = useState<string>('');
    const [code6, setCode6] = useState<string>('');

    const codeInput2_Ref = useRef<HTMLInputElement>(null);
    const codeInput3_Ref = useRef<HTMLInputElement>(null);
    const codeInput4_Ref = useRef<HTMLInputElement>(null);
    const codeInput5_Ref = useRef<HTMLInputElement>(null);
    const codeInput6_Ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (code1.trim() === '' || code2.trim() === '' || code3.trim() === '' || code4.trim() === '' || code5.trim() === '' || code6.trim() === '') {
            setIsValidConfirmationCode(false);
        } else {
            setIsValidConfirmationCode(true);
        }
        setConfirmationCode(`${code1}${code2}${code3}${code4}${code5}${code6}`);
    }, [code1, code2, code3, code4, code5, code6]);

    const handleCodeChange = (value: string, setCode: (val: string) => void, nextInputRef?: RefObject<HTMLInputElement>) => {
        setCode(value.trim());
        if (value.trim() !== '') {
            nextInputRef?.current?.focus();
        }
    }

    const handleCodePaste = (text: string) => {
        const characters: string[] = text.trim().split('');
        setCode1(characters[0] ?? '');
        setCode2(characters[1] ?? '');
        setCode3(characters[2] ?? '');
        setCode4(characters[3] ?? '');
        setCode5(characters[4] ?? '');
        setCode6(characters[5] ?? '');
    }

    return (
        <>
            <div className="flex flex-row gap-2 md:gap-5">
                <div className={inputSizeClass}>
                    <input
                        autoFocus
                        className="w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-xl border border-gray-200 focus:border-primary-color text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-color"
                        type="text"
                        maxLength={1}
                        value={code1}
                        onChange={(e) => { handleCodeChange(e.target.value, setCode1, codeInput2_Ref) }}
                        onPaste={(e) => handleCodePaste(e.clipboardData.getData('Text'))}
                    />
                </div>
                <div className={inputSizeClass}>
                    <input
                        ref={codeInput2_Ref}
                        className="w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-xl border border-gray-200 focus:border-primary-color text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-color"
                        type="text"
                        maxLength={1}
                        value={code2}
                        onChange={(e) => { handleCodeChange(e.target.value, setCode2, codeInput3_Ref) }}
                    />
                </div>
                <div className={inputSizeClass}>
                    <input
                        ref={codeInput3_Ref}
                        className="w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-xl border border-gray-200 focus:border-primary-color text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-color"
                        type="text"
                        maxLength={1}
                        value={code3}
                        onChange={(e) => { handleCodeChange(e.target.value, setCode3, codeInput4_Ref) }}
                    />
                </div>
                <div className={inputSizeClass}>
                    <input
                        ref={codeInput4_Ref}
                        className="w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-xl border border-gray-200 focus:border-primary-color text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-color"
                        type="text"
                        maxLength={1}
                        value={code4}
                        onChange={(e) => { handleCodeChange(e.target.value, setCode4, codeInput5_Ref) }}
                    />
                </div>
                <div className={inputSizeClass}>
                    <input
                        ref={codeInput5_Ref}
                        className="w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-xl border border-gray-200 focus:border-primary-color text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-color"
                        type="text"
                        maxLength={1}
                        value={code5}
                        onChange={(e) => { handleCodeChange(e.target.value, setCode5, codeInput6_Ref) }}
                    />
                </div>
                <div className={inputSizeClass}>
                    <input
                        ref={codeInput6_Ref}
                        className="w-full h-full flex flex-col items-center justify-center text-center px-1 outline-none rounded-xl border border-gray-200 focus:border-primary-color text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-primary-color"
                        type="text"
                        maxLength={1}
                        value={code6}
                        onChange={(e) => { handleCodeChange(e.target.value, setCode6) }}
                    />
                </div>
            </div>
        </>
    );
};

export default ConfirmationCodeInput;
