import { StepI } from "../types/ui/progressBar";
import { UserRolesEnum } from "../enums/userRoles";
import { DropdownOptionI } from "../types/ui/dropdowns";
import { getKeyByValue } from "../utils/objectHelpers";
import { OnboardingStepsEnum } from "../enums/onboarding";
import { USER_ROLES_MAP } from "./common";

export const DEFAULT_ONBOARDING_STEPS: StepI[] = [
  { id: OnboardingStepsEnum.GET_STARTED, status: 'current'},
  { id: OnboardingStepsEnum.SETUP_USER, status: 'upcoming'},
  { id: OnboardingStepsEnum.SETUP_PASSWORD, status: 'upcoming'},
  { id: OnboardingStepsEnum.SUBMIT_CONFIRMATION_CODE, status: 'upcoming'},
  { id: OnboardingStepsEnum.SETUP_ORGANIZATION, status: 'upcoming'},
  { id: OnboardingStepsEnum.INVITE_TEAMMATES, status: 'upcoming'},
];

export const DEFAULT_DESIGNATION = 'Team Member';

export const ROLES_DROPDOWN_OPTIONS: DropdownOptionI[] = [
  { value: getKeyByValue(USER_ROLES_MAP, UserRolesEnum.ADMIN)!, label: UserRolesEnum.ADMIN },
  { value: getKeyByValue(USER_ROLES_MAP, UserRolesEnum.COLLABORATOR)!, label: UserRolesEnum.COLLABORATOR },
  { value: getKeyByValue(USER_ROLES_MAP, UserRolesEnum.MEMBER)!, label: UserRolesEnum.MEMBER },
];