import React from 'react';
import { AdvancedMenuI, AdvancedMenuItemI } from '../../types/ui/menus';
import { Transition } from '@headlessui/react';

const AdvancedMenu = (props: AdvancedMenuI) => {

    const { title, menuItems } = props;

    const renderMenuItem = (item: AdvancedMenuItemI, id: number) => {
        const { icon: Icon } = item.iconConfig;

        return (
            <div key={id} className='flex flex-row items-center gap-4 px-4 py-4 hover:bg-gray-50' onClick={() => item.onClick()}>
                <div className={`w-10 h-10 ${item.iconConfig.bgColorClass} flex items-center justify-center rounded-lg border ${item.iconConfig.borderColorClass}`}>
                    <Icon size={16} className={item.iconConfig.iconColorClass} />
                </div>
                <div>
                    <div className='text-sm font-medium text-gray-700'>{item.text}</div>
                    <div className='text-xs font-medium text-slate-400'>{item.description}</div>
                </div>
            </div>
        )
    };

    return (
        <Transition
            appear={true}
            show={true}
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <div className="overflow-hidden rounded-lg bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)] mb-2">
                {title && <div className='w-full text-xs font-medium text-gray-900 bg-gray-50 p-3'>{title}</div>}
                <div className="flex flex-col py-4">
                    {menuItems.map((item: AdvancedMenuItemI, index) =>
                        renderMenuItem(item, index)
                    )}
                </div>
            </div>
        </Transition >
    )
}

export default AdvancedMenu;
