import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SelectedDateRangeI } from '../../../types/fetch/events';
import moment from 'moment';
import { subDays } from 'date-fns';

interface FetchEventsStateI {
    selectedDateRange: SelectedDateRangeI,
    selectedEventSubCategoryName?: string
};

const initialState: FetchEventsStateI = {
    selectedDateRange: {
        startDate: moment(subDays(new Date(), 29)).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
    }
};

export const fetchEventsSlice = createSlice({
    name: 'fetch-events',
    initialState,
    reducers: {
        setSelectedDateRange: (state, action: PayloadAction<SelectedDateRangeI>) => {
            state.selectedDateRange = action.payload;
        },
        setSelectedEventSubCategoryName: (state, action: PayloadAction<string>) => {
            state.selectedEventSubCategoryName = action.payload;
        },
        clearFetchEventsState: (state) => {
            state.selectedDateRange = {
                startDate: moment(subDays(new Date(), 29)).format('YYYY-MM-DD'),
                endDate: moment(new Date()).format('YYYY-MM-DD'),
            };
            state.selectedEventSubCategoryName = undefined;
        }
    },
})

export const { setSelectedDateRange, setSelectedEventSubCategoryName, clearFetchEventsState } = fetchEventsSlice.actions;
export default fetchEventsSlice.reducer;