import moment from "moment";
import { useMemo } from "react";
import SimpleMenu from "../../../components/Menus/SimpleMenu";
import { OnboardingUserDetailsI } from "../../../types/onboarding";
import UserAvatar from "../../../components/Avatars/UserAvatar";

interface UserProfileI {
    onboardingUserDetails: OnboardingUserDetailsI,
}

const UserProfile = (props: UserProfileI) => {

    const { onboardingUserDetails } = props;

    const hasName: Boolean = useMemo(() => {
        return !!(onboardingUserDetails.user.given_name || onboardingUserDetails.user.family_name);
    }, [onboardingUserDetails]);

    return (
        <div className='flex items-center justify-center w-full h-full'>
            <div className='flex flex-col bg-white rounded-lg w-1/2 h-1/2'>
                <div className="flex flex-col basis-full shrink-0 rounded-lg relative gap-5">
                    <div className="absolute z-[2] h-24 w-full rounded-bl-[44px] rounded-tl-lg rounded-tr-lg bg-gradient-to-r from-primary-color to-white"></div>
                    <div className="absolute right-5 top-5 z-[4]">
                        <div className="bg-white rounded-3xl flex justify-center items-center p-1">
                            <SimpleMenu
                                id={"options-menu"}
                                menuItems={[]}
                                buttonStylingClasses='text-primary-color hover:cursor-default'
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-1">
                        <div className="mt-12 z-[3]">
                            <UserAvatar currentUser={onboardingUserDetails} className={'!size-20'} initialsClassName={'!text-3xl'} />
                        </div>
                        <div className="flex flex-col items-center justify-center w-full gap-2">
                            <div className="flex w-full items-center justify-center text-slate-600 gap-x-1 mx-8">
                                <span
                                    className="text-center max-w-full rounded-md border-0 sm:text-sm text-lg font-normal text-slate-600"
                                >
                                    {!hasName ? '<Name>' : `${onboardingUserDetails.user.given_name} ${onboardingUserDetails.user.family_name}`}
                                </span>
                            </div>
                            <div className="text-sm font-normal text-slate-400 truncate max-w-full px-4">{onboardingUserDetails.user.email}</div>
                            <div className="flex mt-3 w-full justify-center px-4">
                                <div
                                    className="flex items-center max-w-full text-slate-600">
                                    <span
                                        className={"truncate py-[8px] my-auto rounded-md border-0 text-sm font-normal text-slate-600"}
                                    >
                                        {onboardingUserDetails.org.role_name}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="text-xs font-normal text-slate-400">Profile Activated: {moment(onboardingUserDetails.user.created_at).format('MM/DD/YY')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 px-6 pt-5 border-t-[1px] border-t-slate-100">
                        <div className="flex justify-between">
                            <div className="text-xs font-normal text-slate-600">Collections Subscribed</div>
                            <div className="text-primary-color text-xs font-medium">{0}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default UserProfile;