import React, { useState, DetailedHTMLProps, TextareaHTMLAttributes, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, SendHorizontal, X } from 'lucide-react';
import { Popover } from 'react-tiny-popover'
import CollectionsSelectList from '../ChatInputComponents/CollectionsSelectList';
import { CommandLineOptionI, CommandLineOptionIdType } from '../../types/fetch/chatRoom';
import { CommandLineOptionsEnum, MsgTypeEnum } from '../../enums/fetch/chatRoom';
import { ROUTE_FETCH_CHAT_ROOM } from '../../constants/routes';
import { COMMAND_LINE_OPTIONS } from '../../constants/fetch/chatRoom';
import IconButton from '../IconButton/IconButton';
import CommandLinePopoverContent from '../ChatInputComponents/CommandLinePopoverContent';
import styles from "./GlobalChatInput.module.css";
import { classNames } from '../../utils/styleHelpers';

const GlobalChatInput = () => {

    const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>([]);
    const [inputMsg, setInputMsg] = useState<string>('');
    const [selectedCommandLineOptId, setSelectedCommandLineOptId] = useState<CommandLineOptionIdType | null>(null);

    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const navigate = useNavigate();

    const isPopoverOpen: boolean = useMemo(() => {
        return inputMsg === '//' && !selectedCommandLineOptId;
    }, [inputMsg, selectedCommandLineOptId]);

    const handleSubmitMsg = () => {
        if (selectedCommandLineOptId) {
            const msgType = selectedCommandLineOptId === CommandLineOptionsEnum.DOCUMENT ? MsgTypeEnum.DOCUMENT : MsgTypeEnum.KEYWORD
            navigate(ROUTE_FETCH_CHAT_ROOM, { state: { chat: { msg: inputMsg, msgType, collectionIds: selectedCollectionIds } } });
        } else {
            navigate(ROUTE_FETCH_CHAT_ROOM, { state: { chat: { msg: inputMsg, collectionIds: selectedCollectionIds } } });
        }
    }

    const handleInputKeyDown = (event: DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            handleSubmitMsg();
        }
    };

    const handleCommandLineOptSelect = (selectedOptionId: CommandLineOptionIdType) => {
        setSelectedCommandLineOptId(selectedOptionId);
        setInputMsg('');
        inputRef.current?.focus();
    };

    const renderCommandLineOptTag = () => {
        const tagConfig: CommandLineOptionI = COMMAND_LINE_OPTIONS.find((option: CommandLineOptionI) => option.id === selectedCommandLineOptId)!;
        const { icon: Icon } = tagConfig;

        return (
            <div className={`flex items-center border p-1 gap-1 rounded-lg ${tagConfig.textColorClass} ${tagConfig.bgColorClass} ${tagConfig.borderColorClass}`}>
                <div><Icon className='w-4 h-4' /></div>
                <div className='text-sm font-medium'>{tagConfig.title}</div>
                <IconButton icon={X} btnStylingClasses={`${tagConfig.removeIconBgColorClass} w-4 h-4`} iconStylingClasses={tagConfig.textColorClass} handleClick={() => setSelectedCommandLineOptId(null)} />
            </div>
        );
    }

    return (
        <div className='w-7/12 bg-slate-100 h-12 rounded-md px-1 my-auto absolute left-1/2 -translate-x-1/2 z-[10]'>
            <div className='w-full h-full flex flex-row'>
                <div className='w-4/12'>
                    <CollectionsSelectList
                        setFilterCollectionIds={setSelectedCollectionIds}
                        listPosition='bottom'
                        widthClass='w-full h-full'
                    />
                </div>
                <div className='w-8/12'>
                    <Popover
                        isOpen={isPopoverOpen}
                        positions={'bottom'}
                        content={<CommandLinePopoverContent handleOptionSelect={handleCommandLineOptSelect} />}
                        align='start'
                    >
                        <div className="flex">
                            <div className='w-11 justify-start py-3 pl-3 pr-2 text-slate-400 '>
                                <Search />
                            </div>
                            <div className={selectedCommandLineOptId ? 'w-[500px]' : 'w-[600px]'}>
                                <textarea
                                    ref={inputRef}
                                    rows={2}
                                    name="message"
                                    id="message"
                                    className={classNames(styles.textarea, "light-scroll-bar block w-full resize-none border-0 bg-transparent sm:pt-[3px] md:pt-[3px] lg:pt-[3px] text-slate-600 placeholder:text-gray-400 focus:ring-0 sm:text-sm h-[45px]")}
                                    value={inputMsg}
                                    onChange={(e) => setInputMsg(e.target.value)}
                                    placeholder={!selectedCommandLineOptId ? 'Enter your search query or use // for document or keyword search' : ''}
                                    onKeyDown={handleInputKeyDown}
                                />
                            </div>
                            {
                                selectedCommandLineOptId &&
                                <div className='w-[120px] flex pt-2 max-h-10'>
                                    {renderCommandLineOptTag()}
                                </div>
                            }
                            <div className='w-16 py-2 pl-4 flex justify-center'>
                                <IconButton
                                    icon={SendHorizontal}
                                    isDisabled={inputMsg.trim() === ''}
                                    btnStylingClasses='!bg-white text-#0D0D0D rounded-full w-[32px] h-[32px]'
                                    iconStylingClasses='w-[24px] h-[24px]'
                                    handleClick={() => { handleSubmitMsg() }}
                                />
                            </div>
                        </div>
                    </Popover>
                </div>
            </div>
        </div>
    )
}

export default GlobalChatInput;