export enum FeedbackEnum {
    POSITIVE = 'positive',
    NEGATIVE = 'negative'
};

export enum DocumentTypeEnum {
    FILE = 'file',
    SENSO = 'senso',
    WEB = 'web',
    TIPTAP = 'tiptap'
}

export enum KeyboardKeysEnum {
    ENTER = 13,
    SPACE = 32,
    COMMA = 188
}

export enum TiptapEditorModeEnum {
    EDIT = 'edit',
    VIEW = 'view'
}