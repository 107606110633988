const awsmobile = {
    "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "your-identity-pool-id", 
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LDEdhqxq0",
    "aws_user_pools_web_client_id": "33fhos7u71ntt5lj96g5tn1cp0",
    "aws_user_files_s3_bucket": "senso-static-files",
    "aws_user_files_s3_bucket_region": "ca-central-1" // make sure this line is included and correct
};

export default awsmobile;
