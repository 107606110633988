import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EchoKnowledgeStateI {
    isAddNewCategoryBtnClicked: boolean
};

const initialState: EchoKnowledgeStateI = {
    isAddNewCategoryBtnClicked: false
};

export const echoKnowledgeSlice = createSlice({
    name: 'echo-knowledge',
    initialState,
    reducers: {
        setIsAddNewCategoryBtnClicked: (state, action: PayloadAction<boolean>) => {
            state.isAddNewCategoryBtnClicked = action.payload;
        }
    },
})

export const { setIsAddNewCategoryBtnClicked } = echoKnowledgeSlice.actions;
export default echoKnowledgeSlice.reducer;