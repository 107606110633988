export const validatePassword = (password: string) => {
    let errorMessage = "";
    if (password.length < 8) {
        errorMessage = "Password should be at least 8 characters long";
    } else if (!/[A-Z]/.test(password)) {
        errorMessage = "Password should contain at least one uppercase letter";
    } else if (!/[a-z]/.test(password)) {
        errorMessage = "Password should contain at least one lowercase letter";
    } else if (!/[0-9]/.test(password)) {
        errorMessage = "Password should contain at least one number";
    } else if (!/[^A-Za-z0-9]/.test(password)) {
        errorMessage = "Password should contain at least one special character";
    }
    return errorMessage;
};