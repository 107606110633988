import React from 'react';
import { classNames } from '../../utils/styleHelpers'
import { Fragment } from 'react'
import { Menu as ReactMenu, Transition } from '@headlessui/react'
import { SimpleMenuItemI } from '../../types/ui/menus';
import { LucideIcon, MoreHorizontal } from 'lucide-react';

interface SimpleMenuI {
  id: string,
  menuIcon?: LucideIcon,
  menuItems: SimpleMenuItemI[],
  buttonStylingClasses?: string,
  iconStylingClasses?: string,
  menuAlignment?: 'left' | 'right'
}

const SimpleMenu = (props: SimpleMenuI) => {

  const { id, menuIcon: MenuIcon = MoreHorizontal, menuItems, buttonStylingClasses = '', iconStylingClasses = '', menuAlignment = 'left' } = props;

  const renderMenuItem = (item: SimpleMenuItemI) => {
    if (item.icon) {
      const { icon: Icon } = item;
      return <><Icon className={`size-5 ${item.stylingClasses}`} /><span className={`pl-3 ${item.stylingClasses}`}>{item.text}</span></>
    } else {
      return <span className='pl-3'>{item.text}</span>
    }
  }

  return (
    <ReactMenu as="div" className="relative inline-block text-left">
      <div>
        <ReactMenu.Button className={classNames("flex h-5 w-5 items-center text-gray-400 bg-transparent", buttonStylingClasses)}>
          <MenuIcon className={classNames('h-5 w-5', iconStylingClasses)} aria-hidden="true" />
        </ReactMenu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <ReactMenu.Items className={classNames("absolute z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", menuAlignment === 'left' ? 'right-0 ' : 'left-0')}>
          <div className="py-1">
            {menuItems.map((item: SimpleMenuItemI, index) => (
              <ReactMenu.Item key={index}>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? 'bg-gray-100' : 'text-slate-500',
                      ' text-slate-500 px-4 min-w-[180px] py-2 text-sm flex items-start justify-start'
                    )}
                    onClick={() => item.onClick(id)}
                  >
                    {renderMenuItem(item)}
                  </span>
                )}
              </ReactMenu.Item>
            ))}
          </div>
        </ReactMenu.Items>
      </Transition>
    </ReactMenu>
  )
}

export default SimpleMenu;
