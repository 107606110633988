import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IndexChatRoomStateI {
    isSavedQueriesPanelOpen: boolean
};

const initialState: IndexChatRoomStateI = {
    isSavedQueriesPanelOpen: false,
};

export const indexChatRoomSlice = createSlice({
    name: 'index-chat-room',
    initialState,
    reducers: {
        setIsSavedQueriesPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.isSavedQueriesPanelOpen = action.payload;
        },
    },
})

export const { setIsSavedQueriesPanelOpen } = indexChatRoomSlice.actions;
export default indexChatRoomSlice.reducer;