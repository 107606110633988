import React, { useState, useEffect } from 'react';
import styles from './Avatar.module.css';
import { classNames } from '../../utils/styleHelpers';

const UserAvatar = ({ currentUser, className = '', initialsClassName = '' }) => {
    const [initials, setInitials] = useState('?');

    useEffect(() => {
        if (currentUser?.user?.given_name && currentUser?.user?.family_name) {
            const givenName = currentUser.user.given_name;
            const familyName = currentUser.user.family_name;
            setInitials(`${givenName.charAt(0)}${familyName.charAt(0)}`);
        }
    }, [currentUser]);

    const avatarClass = className ? `${styles.avatar} ${className}` : styles.avatar;

    return (
        <div className={avatarClass}>
            {currentUser?.user?.avatar
                ? <img src={currentUser?.user?.avatar} alt="User Avatar" className={styles.avatarImage} />
                : <div className={classNames(`${styles.initials}`, initialsClassName)}>{initials.toUpperCase()}</div>
            }
        </div>
    );
};

export default UserAvatar;
