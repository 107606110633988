import { useState } from "react";
import EditableAvatar from "../../../../components/Avatars/EditableAvatar";
import defaultOrgAvatarUrl from '../../../../images/default-org-avatar.png';
import { OnboardingOrganizationI, OnboardingUserDetailsI } from "../../../../types/onboarding";
import { INVALID_ORG_NAME_ERROR } from "../../../../constants/messages";
import { updateOnboardingOrg, uploadOnboardingOrgLogo } from "../../../../services/api/orgAPI";

interface LeftComponentI {
    onboardingUserDetails: OnboardingUserDetailsI,
    inviteId: string,
    setOnboardingUserDetails: (onboardinUserDetails: OnboardingUserDetailsI) => void,
    handleGotoNextStep: () => void
}

const LeftComponent = (props: LeftComponentI) => {

    const { onboardingUserDetails, inviteId, setOnboardingUserDetails, handleGotoNextStep } = props;

    const [isInvalidOrgName, setIsInvaliOrgName] = useState<boolean>(false);

    const handleOrganizationUpdate = (field: keyof OnboardingOrganizationI, value: string) => {
        const updatedCurrentUser: OnboardingUserDetailsI = {
            ...onboardingUserDetails,
            org: {
                ...onboardingUserDetails.org,
                [field]: value
            }
        }
        setOnboardingUserDetails(updatedCurrentUser);
    }

    const handleNextButtonClick = async () => {
        const trimmedOrgName = onboardingUserDetails.org.name.trim();
        if (trimmedOrgName === '') {
            setIsInvaliOrgName(true);
        } else {
            await updateOnboardingOrg(inviteId, trimmedOrgName);
            handleGotoNextStep();
        }
    }

    const handleUploadAvatar = async (file: File) => {
        const response = await uploadOnboardingOrgLogo(inviteId, file);
        handleOrganizationUpdate('logo', response?.data.file_url)
    }

    return (
        <div className="flex flex-col gap-6 mt-10">
            <div className="text-3xl font-medium text-slate-600">Set up your Organization</div>
            <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-4 sm:w-[400px] w-full">
                    <div className="inline-flex">
                        <EditableAvatar
                            avatarDetails={{ name: onboardingUserDetails.org.name ?? '', avatar: onboardingUserDetails.org.logo ?? defaultOrgAvatarUrl }}
                            handleUploadAvatar={handleUploadAvatar}
                        />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-slate-600 font-semibold text-sm'>Organization Name</label>
                        <input
                            type="text"
                            className='rounded-md border-0 py-1.5 text-slate-600 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6 resize-none pt-3 focus:ring-primary-color'
                            value={onboardingUserDetails.org.name}
                            onChange={(e) => { setIsInvaliOrgName(false); handleOrganizationUpdate('name', e.target.value) }}
                        />
                        {isInvalidOrgName && <div className='text-rose-500 text-sm'>{INVALID_ORG_NAME_ERROR}</div>}
                    </div>
                </div>
                <button
                    className="text-white text-sm font-medium bg-primary-color"
                    onClick={() => handleNextButtonClick()}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default LeftComponent;