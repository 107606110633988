import { useMemo } from "react";
import Listbox from "../../ListBoxes/Listbox";
import { getAmplifySessionHeaders } from "../../../services/auth-service";
import { API_URL } from "../../../config";
import { OrganizationI } from "../../../types/organization";
import { setAllOrganizations, setSelectedOrganization } from "../../../state/features/organization/organizationSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { ListboxOptionI } from "../../../types/ui/listBoxes";
import { getUserDetails } from "../../../services/api/userAPI";
import { setCurrentUser, setFirstCollection } from "../../../state/features/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_FETCH_EVENTS, ROUTE_PROFILE } from "../../../constants/routes";
import { clearFetchEventsState } from "../../../state/features/fetchEvents/fetchEventsSlice";
import { getFirstCollection } from "../../../services/api-service";
import { ChevronRight, ShieldHalf } from "lucide-react";
import styles from './SideBar.module.css';
import { classNames } from "../../../utils/styleHelpers";

interface SwitchOrgOptionI {
    isSwitchOrgOptionActive: boolean,
    setIsSwitchOrgOptionActive: (isActive: boolean) => void,
    setShowUserMenu: (show: boolean) => void
}

const SwitchOrgOption = (props: SwitchOrgOptionI) => {

    const { isSwitchOrgOptionActive, setIsSwitchOrgOptionActive, setShowUserMenu } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const allOrganizations: OrganizationI[] = useAppSelector((state) => state.organization.allOrganizations);
    const selectedOrganization: OrganizationI | undefined = useAppSelector((state) => state.organization.selectedOrganization);

    const listboxOptions: ListboxOptionI[] = useMemo(() => {
        const mappedOrganizations = allOrganizations.map((org: OrganizationI) => {
            return { imageUrl: org.logo, label: org.name, value: org.org_id }
        })
        return mappedOrganizations;
    }, [allOrganizations]);

    const selectedListboxOption: ListboxOptionI = useMemo(() => {
        return listboxOptions.find((opt: ListboxOptionI) => opt.value === selectedOrganization?.org_id)!
    }, [selectedOrganization, listboxOptions]);

    const handleOrgChange = async (orgId: string) => {
        setShowUserMenu(false);
        setIsSwitchOrgOptionActive(false);

        const headers = await getAmplifySessionHeaders();

        try {
            const response = await fetch(`${API_URL}/users/orgs/`, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify({
                    org_id: orgId,
                }),
            });
            const data = await response.json();
            dispatch(setAllOrganizations(data));

            const currentOrg = data.find((org: OrganizationI) => org.current);
            if (currentOrg) {
                dispatch(setSelectedOrganization(currentOrg))
            }

            const fetchedUser = await getUserDetails();
            dispatch(setCurrentUser(fetchedUser));

            const newFirstCollection = await getFirstCollection();
            dispatch(setFirstCollection(newFirstCollection));

            if (location.pathname.includes(ROUTE_FETCH_EVENTS)) {
                dispatch(clearFetchEventsState());
            }
            navigate(ROUTE_PROFILE);
        } catch (error) {
            console.error('Error changing organization', error);
        }
    };

    return (
        <Listbox
            customButton={
                <div className={classNames(styles.menuItem, 'text-base')} onMouseEnter={() => setIsSwitchOrgOptionActive(true)}>
                    <ShieldHalf size="16" className={styles.otherIcon} />
                    Switch Org
                    {isSwitchOrgOptionActive && <div className="ml-auto"><ChevronRight className="text-[#333]" strokeWidth={1.5} /></div>}
                </div>
            }
            buttonStylingClasses='!p-0 border-0 focus-visible:ring-offset-transparent'
            optionsListStylingClasses='!w-[350px] !left-[189px] !-bottom-5 max-h-64'
            options={listboxOptions ?? []}
            selectedOption={selectedListboxOption}
            listPosition="top"
            openOnMouseHover={true}
            isListboxActive={isSwitchOrgOptionActive}
            setIsListboxActive={setIsSwitchOrgOptionActive}
            setSelectedOption={(option: ListboxOptionI) => handleOrgChange(option.value)} />
    );

}

export default SwitchOrgOption;