import { OnboardingUserDetailsI } from '../../../../types/onboarding';
import OrganizationProfileCard from './OrganizationProfileCard';

interface RightComponentI {
    onboardingUserDetails: OnboardingUserDetailsI,
}

const RightComponent = (props: RightComponentI) => {

    const { onboardingUserDetails } = props;

    return (
        <div className='flex items-center justify-center w-full h-full'>
            <div className='flex flex-col bg-white rounded-lg w-1/2 h-1/2'>
                <OrganizationProfileCard onboardingUserDetails={onboardingUserDetails} />
            </div>
        </div>
    );
}

export default RightComponent;