import React, { forwardRef } from 'react';
import { IconButtonI } from '../../types/ui/iconButton';
import { classNames } from '../../utils/styleHelpers';
import Spinner from '../Loaders/Spinner';

const IconButton = forwardRef<HTMLButtonElement, IconButtonI>((props, ref) => {

    const { icon: Icon, text, showBorder = false, isLoading = false, isDisabled = false, btnStylingClasses, iconStylingClasses, iconStrokeWidth = 2, handleClick } = props;

    return (
        <button
            ref={ref}
            className={classNames(
                text ? 'w-auto text-sm font-medium bg-white px-[12px]' : 'w-10 bg-transparent',
                showBorder ? 'border border-gray-300' : '',
                'h-10 text-gray-700 flex items-center justify-center', btnStylingClasses ? btnStylingClasses : '', isDisabled && 'opacity-40')}
            disabled={isDisabled || isLoading}
            onClick={(e) => handleClick?.(e)}
        >
            {!isLoading ?
                <Icon strokeWidth={iconStrokeWidth} className={classNames(text ? 'mr-[8px]' : '', iconStylingClasses ? iconStylingClasses : '')} />
                : <div className='mr-[8px]'><Spinner size='xsmall' /></div>
            }
            {text}
        </button>
    )
})

export default IconButton;