export enum AdminRolesEnum {
    ADMIN = 'Admin', 
    ORG_ADMIN = 'Org Admin', 
    COLLABORATOR = 'Collaborator'
};

export enum UserRolesEnum {
    ADMIN = 'Admin', 
    ORG_ADMIN = 'Org Admin',
    COLLABORATOR = 'Collaborator',
    SUBSCRIBER = 'Subscriber',
    OWNER = "Owner",
    MEMBER = "Member"
}