import { useEffect, useState } from "react";
import EditableAvatar from "../../../../components/Avatars/EditableAvatar";
import defaultUserAvatarUrl from '../../../../images/default-user-avatar.png';
import { INVALID_EMAIL_ADDRESS_ERROR, INVALID_FULL_NAME_ERROR, UPLOAD_AVATAR_ENETER_EMAIL_FIRST_ERROR } from "../../../../constants/messages";
import { OnboardingUserDetailsI } from "../../../../types/onboarding";
import { UserDetailsI } from "../../../../types/user";
import { DEFAULT_DESIGNATION } from "../../../../constants/onboarding";
import { uploadUserAvatar } from "../../../../services/api/userAPI";
import isEmail from "validator/lib/isEmail";
import { classNames } from "../../../../utils/styleHelpers";

interface LeftComponentI {
    isGenericUser: boolean,
    onboardingUserDetails: OnboardingUserDetailsI,
    setOnboardingUserDetails: (onboardinUserDetails: OnboardingUserDetailsI) => void,
    handleGotoNextStep: () => void
}

const LeftComponent = (props: LeftComponentI) => {

    const { isGenericUser, onboardingUserDetails, setOnboardingUserDetails, handleGotoNextStep } = props;

    const [role, setRole] = useState<string>(DEFAULT_DESIGNATION);
    const [isInvalidName, setIsInvalidName] = useState<boolean>(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
    const [isUploadAvatarError, setIsUploadAvatarError] = useState<boolean>(false);
    const [emailUsedForAvatarUpload, setEmailUsedForAvatarUpload] = useState<string>('');
    const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined);

    useEffect(() => {
        if (avatarFile) {
            uploadAvatar(avatarFile);
        }
    }, [avatarFile]);

    const handleUserUpdate = (field: keyof UserDetailsI, value: string) => {

        if (field === 'email' && isUploadAvatarError) {
            setIsUploadAvatarError(false);
        }

        const updatedCurrentUser: OnboardingUserDetailsI = {
            ...onboardingUserDetails,
            user: {
                ...onboardingUserDetails.user,
                [field]: value
            }
        }
        setOnboardingUserDetails(updatedCurrentUser);
    }

    const handleRoleUpdate = (value: string) => {
        const updatedCurrentUser: OnboardingUserDetailsI = {
            ...onboardingUserDetails,
            org: {
                ...onboardingUserDetails.org,
                role_name: value
            }
        }
        setOnboardingUserDetails(updatedCurrentUser);
    }

    const uploadAvatar = async (file: File) => {
        try {
            const response = await uploadUserAvatar(file, onboardingUserDetails.user.email);
            handleUserUpdate('avatar', response.data.file_url);
            setEmailUsedForAvatarUpload(onboardingUserDetails.user.email);
        } catch (e) {
            console.log(e);
        }
    }

    const handleNextButtonClick = async () => {
        if (onboardingUserDetails.user.given_name.trim() === '' || onboardingUserDetails.user.family_name.trim() === '') {
            setIsInvalidName(true);
        } else if (!isEmail(onboardingUserDetails.user.email)) {
            setIsInvalidEmail(true);
        } else {
            // If generic user changes email after uploading avatar, we need to upload avatar for that new email
            if (isGenericUser && emailUsedForAvatarUpload !== onboardingUserDetails.user.email && avatarFile) {
                uploadAvatar(avatarFile);
            }
            handleGotoNextStep();
        }
    }

    const handleUploadAvatar = async (file: File) => {
        if (isGenericUser && !isEmail(onboardingUserDetails.user.email)) {
            setIsUploadAvatarError(true);
        } else {
            setAvatarFile(file);
        }
    }

    return (
        <div className="flex flex-col gap-6 mt-10">
            <div className="text-3xl font-medium text-slate-600">Create Your Profile</div>
            <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-4 sm:w-[400px] w-full">
                    <div className="inline-flex">
                        <EditableAvatar
                            avatarDetails={{ user: { avatar: onboardingUserDetails.user.avatar ?? defaultUserAvatarUrl } }}
                            handleUploadAvatar={handleUploadAvatar}
                        />
                    </div>
                    {isUploadAvatarError && <div className='text-rose-500 text-sm'>{UPLOAD_AVATAR_ENETER_EMAIL_FIRST_ERROR}</div>}
                    <div className='flex flex-col gap-2'>
                        <div className="flex gap-2 flex-col sm:flex-row">
                            <div className='flex flex-col gap-2 basis-1/2'>
                                <label className='text-slate-600 font-semibold text-sm'>First Name</label>
                                <input
                                    autoFocus
                                    type="text"
                                    className='rounded-md border-0 py-1.5 text-slate-600 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6 resize-none pt-3 focus:ring-primary-color'
                                    value={onboardingUserDetails.user.given_name}
                                    onChange={(e) => { setIsInvalidName(false); handleUserUpdate('given_name', e.target.value); }}
                                />
                            </div>
                            <div className='flex flex-col gap-2 basis-1/2'>
                                <label className='text-slate-600 font-semibold text-sm'>Last Name</label>
                                <input
                                    type="text"
                                    className='rounded-md border-0 py-1.5 text-slate-600 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6 resize-none pt-3 focus:ring-primary-color'
                                    value={onboardingUserDetails.user.family_name}
                                    onChange={(e) => { setIsInvalidName(false); handleUserUpdate('family_name', e.target.value); }}
                                />
                            </div>
                        </div>
                        {isInvalidName && <div className='text-rose-500 text-sm'>{INVALID_FULL_NAME_ERROR}</div>}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className={classNames('font-semibold text-sm', isGenericUser ? 'text-slate-600' : 'text-slate-400')}>Email</label>
                        <input
                            type='text'
                            className={classNames('rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6 resize-none pt-3 focus:ring-primary-color', isGenericUser ? 'text-slate-600' : 'text-slate-400')}
                            value={onboardingUserDetails.user.email}
                            disabled={!isGenericUser}
                            onChange={(e) => { setIsInvalidEmail(false); handleUserUpdate('email', e.target.value); }}
                        />
                        {isInvalidEmail && <div className='text-rose-500 text-sm'>{INVALID_EMAIL_ADDRESS_ERROR}</div>}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-slate-600 font-semibold text-sm'>Title</label>
                        <input
                            type="text"
                            className='rounded-md border-0 py-1.5 text-slate-600 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6 resize-none pt-3 focus:ring-primary-color'
                            value={role}
                            onChange={(e) => { setRole(e.target.value); handleRoleUpdate(e.target.value) }}
                        />
                    </div>
                </div>
                <button
                    className="text-white text-sm font-medium bg-primary-color"
                    onClick={() => handleNextButtonClick()}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default LeftComponent;