import { useMemo, useState } from "react";
import IconButton from "../../../../components/IconButton/IconButton";
import { Info, Link } from "lucide-react";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import Dropdown from "../../../../components/Dropdowns/Dropdown";
import { DropdownOptionI } from "../../../../types/ui/dropdowns";
import { UserRolesEnum } from "../../../../enums/userRoles";
import { getKeyByValue } from "../../../../utils/objectHelpers";
import { ROLES_DROPDOWN_OPTIONS } from "../../../../constants/onboarding";
import TagsInput from "../../../../components/TagsInput/TagsInput";
import isEmail from "validator/lib/isEmail";
import { classNames } from "../../../../utils/styleHelpers";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { createGenericInviteLink, inviteUser } from "../../../../services/api/userAPI";
import { uniq } from "lodash";
import { USER_ROLES_MAP } from "../../../../constants/common";
import { INVITES_SENT_SUCCESS } from "../../../../constants/messages";

interface LeftComponentI {
    handleGotoNextStep: () => void
}

const LeftComponent = (props: LeftComponentI) => {

    const { handleGotoNextStep } = props;

    const [emails, setEmails] = useState<string[]>([]);
    const [hasIncompleteEmail, setHasIncompleteEmail] = useState<boolean>(false);
    const [isAnyInviteButtonClicked, setIsAnyInviteButtonClicked] = useState<boolean>(false);
    const [isResetEmailsInInput, setIsResetEmailsInInput] = useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>(getKeyByValue(USER_ROLES_MAP, UserRolesEnum.ADMIN)!);

    const rolesDropdownLabel: string = useMemo(() => {
        return ROLES_DROPDOWN_OPTIONS.find((opt: DropdownOptionI) => opt.value === roleValue)?.label ?? '';
    }, [roleValue]);

    const isInviteButtonDisabled: boolean = useMemo(() => {
        return emails.length === 0 || hasIncompleteEmail;
    }, [emails, hasIncompleteEmail]);

    const handleResetInvite = () => {
        setEmails([]);
        setIsResetEmailsInInput(true);
        setRoleValue(getKeyByValue(USER_ROLES_MAP, UserRolesEnum.ADMIN)!);
    }

    const handleAddRole = (value: string | number) => {
        setRoleValue(value as string);
    }

    const handleInviteButtonClicked = () => {
        const uniqEmails = uniq(emails);
        uniqEmails.forEach(async (email: string) => {
            await inviteUser(email, Number(roleValue));
        });
        setIsAnyInviteButtonClicked(true);
        toast.success(INVITES_SENT_SUCCESS);
        handleResetInvite();
    }

    const handleInviteByLinkButtonClicked = async () => {
        const response = await createGenericInviteLink();
        setIsAnyInviteButtonClicked(true);
        navigator.clipboard.writeText(response?.data.invite_link);
        toast.success("Link copied!");
    }

    const checkIsValidEmail = (email: string) => {
        return isEmail(email);
    }

    const renderRolesTooltip = () => {
        return (
            <div className="bg-slate-800 p-4">
                <ul className="list-disc">
                    <li className="text-white">
                        <div>
                            <span className="font-bold text-white">Admin: </span>
                            <span className="text-slate-300 font-normal text-sm">Full content management & analytics access to the organization, including user management.</span>
                        </div>
                        <br />
                    </li>
                    <li className="text-white">
                        <div>
                            <span className="font-bold text-white">Collaborators: </span>
                            <span className="text-slate-300 font-normal text-sm">Full content management & analytics access to the organization.</span>
                        </div>
                        <br />
                    </li>
                    <li className="text-white">
                        <div>
                            <span className="font-bold text-white">Members: </span>
                            <span className="text-slate-300 font-normal text-sm">View/download access to content, restricted to One Chat use.</span>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-6 mt-10">
            <div className="text-3xl font-medium text-slate-600">Invite your teammates</div>
            <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-4">
                    <span className="text-slate-400 text-base font-normal">Communicating with senso helps you to communicate with several teammates, through just one video.</span>
                    <div className="flex flex-col">
                        <div className='w-full bg-gray-50 px-3 pt-3 rounded-t-lg flex flex-col gap-3'>
                            <div className="flex justify-start items-center gap-2">
                                <div className="w-2/5">
                                    <Dropdown options={ROLES_DROPDOWN_OPTIONS} label={rolesDropdownLabel} onOptionClick={handleAddRole} />
                                </div>
                                <div>
                                    <Info data-tooltip-id={'roles-tool-tip'} className="text-slate-400" />
                                    <Tooltip id={"roles-tool-tip"} place={"top"} children={renderRolesTooltip()} stylingClasses="!bg-slate-800" />
                                </div>
                            </div>
                            <span className="text-gray-400 text-xs mb-1">Separate multiple email addresses with a comma, space or press enter key</span>
                        </div>
                        <TagsInput handleAddTag={(tags) => setEmails(tags)} checkIsValidTag={checkIsValidEmail} setHasIncompleteTag={setHasIncompleteEmail} isResetTags={isResetEmailsInInput} setIsResetTags={setIsResetEmailsInInput} />
                    </div>
                </div>
                <div className="flex justify-start gap-4">
                    <button
                        className={classNames("text-white text-sm font-medium bg-primary-color", isInviteButtonDisabled && 'opacity-40')}
                        onClick={handleInviteButtonClicked}
                        disabled={isInviteButtonDisabled}
                    >
                        Invite
                    </button>
                    {/* Hide this temporarily */}
                    {/* <IconButton icon={Link} text="Or, invite by links" showBorder btnStylingClasses="border-primary-color text-primary-color" iconStylingClasses="max-sm:size-4" handleClick={handleInviteByLinkButtonClicked} /> */}
                    <button
                        className={classNames(" text-sm font-medium ml-auto", !isAnyInviteButtonClicked ? 'text-slate-400 bg-white border-slate-300 border' : 'text-white bg-primary-color')}
                        onClick={handleGotoNextStep}
                    >
                        {!isAnyInviteButtonClicked ? 'Skip for now' : 'Next'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LeftComponent;