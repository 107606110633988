import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { HIDE_HEADER_FROM_ROUTES } from "../constants/mainLayout";

const useLayout = () => {

    const location = useLocation();

    const hideDefaultHeader: boolean = useMemo(() => {

        if (HIDE_HEADER_FROM_ROUTES.includes(location.pathname) || location.pathname.includes('/content/')) {
            return true;
        } else {
            return false
        }
    }, [location.pathname]);

    return { hideDefaultHeader };
};

export default useLayout;
