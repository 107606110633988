import { useNavigate } from 'react-router-dom';
import { ROUTE_LOGIN } from '../../../constants/routes';

interface OnboardigAlreadyCompletedI {
    orgLogo: string
}

const OnboardigAlreadyCompleted = (props: OnboardigAlreadyCompletedI) => {

    const { orgLogo } = props;

    const navigate = useNavigate();

    return (
        <div className='flex w-full h-svh justify-center items-center bg-slate-100'>
            <div className='flex flex-col p-10 max-lg:mx-10 lg:w-1/2 bg-white shadow-lg rounded-xl gap-10'>
                <div className='flex flex-col items-center justify-center gap-4'>
                    <img src={orgLogo} alt='logo' className='sm:h-16 h-12' />
                    <div className='text-center'>
                        <span className='text-slate-600 text-3xl'>Onboarding already completed </span>
                    </div>
                    <div className='text-base font-medium text-slate-400 text-center'>
                        Onboarding already complete. Please log in.
                    </div>
                </div>
                <div className='flex justify-center'>
                    <button
                        className="text-white text-sm font-medium bg-primary-color"
                        onClick={() => navigate(ROUTE_LOGIN)}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OnboardigAlreadyCompleted;