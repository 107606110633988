export enum OnboardingStepsEnum {
    GET_STARTED = 'get-started',
    SETUP_USER = 'setup-user',
    SETUP_PASSWORD = 'setup-password',
    SUBMIT_CONFIRMATION_CODE = 'submit-confirmation-code',
    SETUP_ORGANIZATION = 'setup-organization',
    INVITE_TEAMMATES = "invite-teammates",
}

export enum OnboardingTypesEnum {
    ORG_AND_USER = 'org_and_user',
    USER_ONLY = "user_only",
    NONE = "none"
}

export enum InviteStatusEnum {
    INVITED = 0,
    ACTIVE = 1,
    REVOKED = 2,
    EXPIRED = 3,
    DELETED = 4
}

export enum InvitationStatusEnum {
    ONBOARDING_COMPLETED = 'onboarding-completed',
    ONBOARDING_NOT_COMPLETED = 'onboarding-not-completed',
    INVITATION_EXPIRED = 'invitation-expired'
}

export enum OnboardingProgessObjectKeysEnum {
    PASSWORD_AND_USER_SETUP_COMPLETE = 'password_and_user_setup_complete',
    ORG_SETUP_COMPLETE = 'org_setup_complete',
    INVITE_TEAMMATES_COMPLETE = 'invite_teammates_complete'
}