import { Camera } from "lucide-react";
import { ChangeEvent, useState } from "react";
import UserAvatar from "./UserAvatar";
import { classNames } from "../../utils/styleHelpers";
import { OrganizationAvatarDetailsI, UserAvatarDetailsI } from "../../types/ui/avatars";
import OrganizationAvatar from "./OrganizationAvatar";

interface EditableAvatarI {
    avatarDetails: UserAvatarDetailsI | OrganizationAvatarDetailsI,
    containerDivStylingClasses?: string,
    avatarSizeClass?: string,
    avatarTextSizeClass?: string,
    handleUploadAvatar: (file: File) => Promise<void>
}

const EditableAvatar = (props: EditableAvatarI) => {

    const { containerDivStylingClasses = '', avatarSizeClass = '!size-20', avatarTextSizeClass = '!text-3xl', handleUploadAvatar } = props;

    const [isMouseHoverOnAvatar, setIsMouseHoverOnAvatar] = useState<boolean>(false);

    const isUserAvatar = (avatarDetails: UserAvatarDetailsI | OrganizationAvatarDetailsI): avatarDetails is UserAvatarDetailsI => {
        return 'user' in avatarDetails;
    }

    return (
        <div
            className={classNames("relative", containerDivStylingClasses)}
            onMouseOver={() => setIsMouseHoverOnAvatar(true)}
            onMouseOut={() => setIsMouseHoverOnAvatar(false)}
        >
            {isUserAvatar(props.avatarDetails) ?
                <UserAvatar currentUser={props.avatarDetails} className={avatarSizeClass} initialsClassName={avatarTextSizeClass} />
                :
                <OrganizationAvatar orgAvatarDetails={props.avatarDetails} avatarClassName={avatarSizeClass} initialsClassName={avatarTextSizeClass} />
            }
            {isMouseHoverOnAvatar &&
                <div
                    className={classNames("absolute top-0 flex items-center justify-center flex-col bg-gray-900 bg-opacity-70 rounded-full hover:cursor-pointer", avatarSizeClass)}
                    onClick={() => { document.getElementById('imageInput')?.click(); }}
                >
                    <div><Camera className="text-white" /></div>
                    <div className="text-[10px] text-white">Change photo</div>
                </div>
            }
            <input
                type="file"
                id="imageInput"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                        handleUploadAvatar(e.target.files[0])
                    }
                }}
                onClick={(event) => {
                    const element = event.target as HTMLInputElement;
                    element.value = '';
                }}
                accept="image/*"
                className="hidden"
            />
        </div>
    );
}

export default EditableAvatar;