import moment from "moment";
import OrganizationAvatar from "../../../../components/Avatars/OrganizationAvatar";
import { OnboardingUserDetailsI } from "../../../../types/onboarding";

interface OrganizationProfileCardI {
    onboardingUserDetails: OnboardingUserDetailsI,
}

const OrganizationProfileCard = (props: OrganizationProfileCardI) => {

    const { onboardingUserDetails } = props;

    return (
        <div className="flex flex-col basis-full shrink-0 rounded-lg relative gap-5">
            <div className="absolute z-[2] h-24 w-full rounded-bl-[44px] rounded-tl-lg rounded-tr-lg bg-gradient-to-r from-primary-color to-white"></div>
            <div className="flex flex-col items-center">
                <div className="mt-12 z-[3]">
                    <OrganizationAvatar orgAvatarDetails={{ name: onboardingUserDetails.org.name ?? '', avatar: onboardingUserDetails.org.logo }} avatarClassName='!size-20' initialsClassName='!text-3xl' />
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="flex w-full items-center justify-center text-slate-600 gap-x-1 mx-8">
                        <span
                            className="text-center max-w-full rounded-md border-0 sm:text-sm text-lg font-normal text-slate-600"
                        >
                            {onboardingUserDetails.org.name}
                        </span>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs font-normal text-slate-400">Profile Activated: {moment(onboardingUserDetails.org.created_at).format('MM/DD/YY')}</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-3 px-6 pt-5 border-t-[1px] border-t-slate-100">
                <div className="flex justify-between">
                    <div className="text-xs font-normal text-slate-600">Collections Created</div>
                    <div className="text-primary-color text-xs font-medium">0</div>
                </div>
            </div>
        </div>
    );
}

export default OrganizationProfileCard;