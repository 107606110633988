// public routes
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_LOGIN = '/login';
export const ROUTE_PASSWORD_RESET = '/password_reset';
export const ROUTE_ONBOARDING = '/onboarding/invite/:invite_id';

// common routes
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_ORGANIZATION_SETTINGS = '/organization-settings';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_NOTIFICATION_COMMENTS = '/notifications/:notification_id/comments';
export const ROUTE_PAGE_NOT_FOUND = '/page-not-found';

// echo routes
export const ROUTE_ECHO = '/echo';
export const ROUTE_ECHO_KNOWLEDGE = '/echo/knowledge';
export const ROUTE_ECHO_METRICS = '/echo/metrics';
export const ROUTE_ECHO_COLLECTIONS = '/echo/collections';
export const ROUTE_ECHO_CUSTOMERS = '/echo/customers';
export const ROUTE_ECHO_EVENTS = '/echo/events';
export const ROUTE_ECHO_EVENT = `${ROUTE_ECHO_EVENTS}/:event_id`;
export const ROUTE_ECHO_EVENT_QUERIES = `${ROUTE_ECHO_EVENT}/queries`;
export const ROUTE_ECHO_TAGS = '/echo/tags';

// fetch routes
export const ROUTE_FETCH = '/fetch';
export const ROUTE_FETCH_KNOWLEDGE = '/fetch/knowledge';
export const ROUTE_FETCH_KNOWLEDGE_CONTENT = `${ROUTE_FETCH_KNOWLEDGE}/content`;
export const ROUTE_FETCH_METRICS_ANALYTICS = '/fetch/metrics/analytics';
export const ROUTE_FETCH_METRICS_QUERIES = '/fetch/metrics/queries';
export const ROUTE_FETCH_COLLECTIONS = '/fetch/collections';
export const ROUTE_FETCH_COLLECTION = `${ROUTE_FETCH_COLLECTIONS}/:collection_id`;
export const ROUTE_FETCH_COLLECTION_CONTENT = `${ROUTE_FETCH_COLLECTION}/content/:content_id`;
export const ROUTE_FETCH_COLLECTION_CONTENT_REFRESH = '/fetch/collection-content/refresh';
export const ROUTE_FETCH_CHAT_ROOM = '/fetch/chat-room';
export const ROUTE_FETCH_EVENTS = '/fetch/events';
export const ROUTE_FETCH_EVENT_CATEGORY = `${ROUTE_FETCH_EVENTS}/:category_id`;
export const ROUTE_FETCH_EVENT_SUB_CATEGORY = `${ROUTE_FETCH_EVENT_CATEGORY}/sub/:sub_category_id`;
export const ROUTE_FETCH_TAGS = '/fetch/tags';

// live survey routes
export const ROUTE_LS_EVENTS = '/echo/ls/events';
export const ROUTE_LS_EVENT_CATEGORY = `${ROUTE_LS_EVENTS}/:category_id`;
export const ROUTE_LS_EVENT_SUB_CATEGORY = `${ROUTE_LS_EVENT_CATEGORY}/sub/:sub_category_id`;

//index routes
export const ROUTE_INDEX_CHAT_ROOM = '/index';




