import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BreadcrumbPageI } from '../../../types/ui/breadcrumb';

interface BreadcrumbI {
    breadcrumbPages: BreadcrumbPageI[],
};

const initialState: BreadcrumbI = {
    breadcrumbPages: []
};

export const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setBreadcrumbPages: (state, action: PayloadAction<BreadcrumbPageI[]>) => {
            state.breadcrumbPages = action.payload;
        },
        clearBreadcrumbPages: (state) => {
            state.breadcrumbPages = [];
        }
    },
})

export const { setBreadcrumbPages, clearBreadcrumbPages } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;