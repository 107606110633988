import React, { ReactNode } from "react";
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip'
import { classNames } from "../../utils/styleHelpers";
interface TooltipI {
    id: string,
    place?: PlacesType,
    content?: string,
    children?: ReactNode,
    stylingClasses?: string
}

export const Tooltip = (props: TooltipI) => {
    const { id, place = 'right', content, children, stylingClasses = '!bg-white' } = props;
    return <ReactTooltip id={id} place={place} content={content} children={children} border={`1px solid var(--primary-color)`} opacity={1} className={classNames('max-w-[300px] z-50 !text-slate-600 !rounded-lg shadow-lg', stylingClasses)} />;
}

export default Tooltip;
