import { API_URL } from '../config';
import { getAmplifySessionHeaders } from './auth-service';
import { COLLECTIONS_USER_ROLES_MAP } from '../constants/fetch/collections';

const permissionMap = {
  "Subscriber": 0,
  "Admin": 1,
  "Remove": -1,
};

export async function createCollection(name) {
  const headers = await getAmplifySessionHeaders();

  try {
    const response = await fetch(`${API_URL}/collections/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating collection:', error);
    throw error;
  }
}

export async function createUser(cognitoId, email, givenName, familyName) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    cognito_id: cognitoId,
    email: email,
    given_name: givenName,
    family_name: familyName,
  };

  try {
    const response = await fetch(`${API_URL}/users/create/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating collection:', error);
    throw error;
  }
}

export async function listCollections() {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function getFirstCollection() {
  const headers = await getAmplifySessionHeaders();
  try {
    const response = await fetch(`${API_URL}/collections/first/read/`, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting details of first collection:', error);
    throw error;
  }
}

export async function listTemplates() {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/templates/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function getUser() {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/users/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function getCollection(collection_id) {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/${collection_id}/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function putCollection(collectionId, name) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    name
  }

  return fetch(`${API_URL}/collections/${collectionId}/`, {
    method: `PUT`,
    headers,
    body: JSON.stringify(data)
  }).then(res => res.json());
}

export async function putCollectionVisibility(collectionId, visibility) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    visibility
  }

  return fetch(`${API_URL}/collections/${collectionId}/`, {
    method: `PUT`,
    headers,
    body: JSON.stringify(data)
  }).then(res => res.json());
}

export async function listDocuments(collection_id) {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/${collection_id}/documents/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function deleteDocument(collection_id, document_id) {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/${collection_id}/documents/${document_id}`, {
    method: 'DELETE',
    headers,
  }).then(res => res.json());
}


export async function postQuery(collectionId, message, stream = false, messages, setMessages, setCurrentMessageIndex, setIsSubmitted, template_id) {
  const headers = await getAmplifySessionHeaders();
  const data = {
    query: message,
    template_id: template_id,
    stream,
  };

  // Create an AbortController instance
  const abortController = new AbortController();
  const signal = abortController.signal;

  const response = await fetch(`${API_URL}/collections/${collectionId}/query/`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
    signal,
  });

  // Check if the request was successful
  let reader = null;
  let lastLine = '';
  if ((response.status === 200) || (response.status === 402) || (response.status === 500)) {
    reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let lastMessage = '';

    const processStream = async (result) => {
      if (result.done) {
        console.error('Stream disconnected unexpectedly');
        return;
      }

      const chunk = decoder.decode(result.value, { stream: true });
      const lines = (lastLine + chunk).split('\n');

      lastLine = lines.pop(); // Store the last (potentially incomplete) line

      for (const line of lines) {
        if (line.trim() !== '') {
          try {
            const event = JSON.parse(line);
            if (event.stop) {
              setMessages((prevMessages) => {
                const index = prevMessages.length - 1;
                const newMessages = [...prevMessages];
                newMessages[index] = { text: newMessages[index].text, align: "left", type: "bot", id: event.query_id, paywall: (response.status === 402), status: event.status, tags: event.tags, sources: event.sources };
                return newMessages;
              });
              setCurrentMessageIndex(-1);
              setIsSubmitted(false);
              break;
            } else {
              setCurrentMessageIndex((prevIndex) => {
                if (prevIndex === -1) {
                  const newIndex = messages.length + 1;
                  setMessages((prevMessages) => {
                    let mmm = "";
                    if (event.status === "Searching Data..." || event.status === "Generating Response..." || event.status === "Analyzing Request...") {
                      mmm = "";
                    } else {
                      mmm = event.data;
                    }
                    const newMessages = [...prevMessages];
                    newMessages[newIndex] = { text: mmm, align: "left", type: "bot", id: event.query_id, paywall: (response.status === 402), status: event.status, tags: event.tags, sources: event.sources };
                    return newMessages;
                  });
                  return newIndex;
                } else {
                  setMessages((prevMessages) => {
                    const newMessages = [...prevMessages];
                    let mmm = "";
                    if (event.status === "Searching Data..." || event.status === "Generating Response..." || event.status === "Analyzing Request...") {
                      mmm = "";
                    } else {
                      mmm = newMessages[prevIndex].text + event.data;
                    }
                    let tagsArray = [];

                    if (event.tags) {
                      const tagsString = event.tags;
                      const trimmedString = tagsString.slice(1, -1);
                      tagsArray = trimmedString.split(',').map(tag => tag.trim());
                    }
                    newMessages[prevIndex] = { text: mmm, align: "left", type: "bot", id: event.query_id, paywall: (response.status === 402), status: event.status, tags: tagsArray, sources: event.sources };
                    return newMessages;
                  });
                  return prevIndex;
                }
              });
            }
          } catch (error) {
            console.error('Error parsing JSON:', error, 'Line:', line);
          }
        }
      }
      return reader.read().then(processStream);
    };

    reader.read().then(processStream);
  } else {
    console.error('Error fetching data:', response.status, response.statusText);
  }

  return () => {
    abortController.abort();
    reader.cancel();
  };
}

export async function createFeedback(collectionId, queryID, type, message) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    type: type,
    message: message
  };

  return fetch(`${API_URL}/collections/query/${queryID}/feedback/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  }).then(res => res.json());
}

export async function listSubscribers(collection_id) {
  const headers = await getAmplifySessionHeaders();

  const response = await fetch(`${API_URL}/collections/${collection_id}/subscribers/`, {
    method: 'GET',
    headers,
  });

  const data = await response.json();

  // Apply the role map to each element in the API response
  const result = data.map(item => ({
    ...item,
    role: COLLECTIONS_USER_ROLES_MAP[item.role] // Replace the role number with the corresponding role text
  }));

  return result;
}

export async function createUpdateSubscriber(collectionId, email, permission) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    email: email,
    permission: permissionMap[permission] || 0
  };

  return fetch(`${API_URL}/collections/${collectionId}/subscribers/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  }).then(res => {
    if (res.status >= 200 && res.status < 300) {
      return 0;
    } else if (res.status === 403) {
      return 1;
    } else if (res.status === 404) {
      return 2;
    } else if (res.status === 400) {
      return 3;
    } else if (res.status === 500) {
      return 4;
    } else {
      throw new Error(`Unexpected status code: ${res.status}`);
    }
  });
}

export async function createUpdateCollectionSubscriber(collectionId, email, permission) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    email: email,
    permission: permission
  };

  return fetch(`${API_URL}/collections/${collectionId}/subscribers/`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  }).then(res => {
    if (res.status >= 200 && res.status < 300) {
      return 0;
    } else if (res.status === 403) {
      return 1;
    } else if (res.status === 404) {
      return 2;
    } else if (res.status === 400) {
      return 3;
    } else if (res.status === 500) {
      return 4;
    } else {
      throw new Error(`Unexpected status code: ${res.status}`);
    }
  });
}


export async function addWebDocument(collectionId, url) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    url
  }

  const response = await fetch(`${API_URL}/collections/${collectionId}/web_document/`, {
    method: `POST`,
    headers,
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
}

export async function addWebDocument2(url) {
  const headers = await getAmplifySessionHeaders();

  const data = {
    url
  }

  const response = await fetch(`${API_URL}/collections/documents/web_upload/`, {
    method: `POST`,
    headers,
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
}

export async function getUserDetails() {
  return { familyName: "Nelson", givenName: "Tom", email: "tn90ca@gmail.com", organizationName: "Personal" }
}

export async function deactivatePlan() {
  const headers = await getAmplifySessionHeaders();
  // Call your backend to update the subscription
  const response = await fetch(`${API_URL}/webhooks/cancel_subscription/`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
}

export async function reactivatePlan() {
  const headers = await getAmplifySessionHeaders();
  // Call your backend to update the subscription
  const response = await fetch(`${API_URL}/webhooks/reactivate_subscription/`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
}

export async function getCollectionContext(collectionId, messages, setMessages, setCurrentMessageIndex, setIsSubmitted) {
  const headers = await getAmplifySessionHeaders();
  const data = {
    stream: true
  };

  // Create an AbortController instance
  const abortController = new AbortController();
  const signal = abortController.signal;

  const response = await fetch(`${API_URL}/collections/${collectionId}/context/`, {
    method: "GET",
    headers: headers,
    signal,
  });

  // Check if the request was successful
  let reader = null;
  if (response.ok) {
    reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    const processStream = async (result) => {
      if (result.done) {
        return;
      }

      const chunk = decoder.decode(result.value, { stream: true });
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.trim() !== '') {
          const event = JSON.parse(line);
          if (event.stop) {
            setCurrentMessageIndex(-1);
            setIsSubmitted(false);
            break;
          } else {
            setCurrentMessageIndex((prevIndex) => {
              if (prevIndex === -1) {
                const newIndex = messages.length;
                setMessages((prevMessages) => {
                  const newMessages = [...prevMessages];
                  newMessages[newIndex] = { text: event.data, align: "left", type: "bot" };
                  return newMessages;
                });
                return newIndex;
              } else {
                setMessages((prevMessages) => {
                  const newMessages = [...prevMessages];
                  newMessages[prevIndex] = { text: newMessages[prevIndex].text + event.data, align: "left", type: "bot" };
                  return newMessages;
                });
                return prevIndex;
              }
            });
          }
        }
      }
      return reader.read().then(processStream);
    };

    reader.read().then(processStream);
  } else {
    console.error('Error fetching data:', response.status, response.statusText);
  }

  return () => {
    abortController.abort();
    reader.cancel();
  };
}

export async function getPosts(collection_id) {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/posts/collections/${collection_id}/posts/`, {
    method: 'GET',
    headers,
  }).then(res => res.json());
}

export async function publishPostAPI(post_id) {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/posts/${post_id}/publish/`, {
    method: 'POST',
    headers,
  }).then(res => res.json());
}

export async function collectionSubscribe(collection_id) {
  const headers = await getAmplifySessionHeaders();

  return fetch(`${API_URL}/collections/${collection_id}/subscribe/`, {
    method: 'POST',
    headers,
  }).then(res => res.json());
}