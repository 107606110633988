import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InteractionCollectionI } from '../../../types/echo/collections';

interface EchoCollectionsStateI {
    selectedTabId: number,
    selectedCollection?: InteractionCollectionI,
    isRunModalOpen: boolean,
    isShareModalOpen: boolean
};

const initialState: EchoCollectionsStateI = {
    selectedTabId: 0,
    isRunModalOpen: false,
    isShareModalOpen: false
};

export const echoCollectionsSlice = createSlice({
    name: 'echo-collections',
    initialState,
    reducers: {
        setSelectedCollection: (state, action: PayloadAction<InteractionCollectionI>) => {
            state.selectedCollection = action.payload;
        },
        setIsRunModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isRunModalOpen = action.payload;
        },
        setIsShareModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isShareModalOpen = action.payload;
        },
        setSelectedTabId: (state, action: PayloadAction<number>) => {
            state.selectedTabId = action.payload;
        },
        clearEchoCollectionsState: (state) => {
            state.selectedCollection = undefined;
            state.isRunModalOpen = false;
            state.isShareModalOpen = false;
            state.selectedTabId = 0;
        }
    },
})

export const { setSelectedCollection, setIsRunModalOpen, setIsShareModalOpen, setSelectedTabId, clearEchoCollectionsState } = echoCollectionsSlice.actions;
export default echoCollectionsSlice.reducer;