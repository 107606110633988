import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../../utils/styleHelpers'
import { TabI } from '../../types/ui/tabs';
import { cloneDeep } from 'lodash';

interface TabsI {
    tabs: TabI[],
    handleTabClick?: (selectedTabId: number) => void
};

const Tabs = (props: TabsI) => {

    const { tabs = [], handleTabClick } = props;

    const [tabsList, setTabsList] = useState<TabI[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        setTabsList(tabs);
    }, [tabs]);

    const onTabClick = (selectedTabId: number, href?: string) => {
        const currentTabsList = cloneDeep(tabsList);

        const prevSelectedTab = currentTabsList.find((tab: TabI) => tab.current === true)!;
        prevSelectedTab.current = false;

        const currentSelectedTab = currentTabsList.find((tab: TabI) => tab.id === selectedTabId)!;
        currentSelectedTab.current = true;

        setTabsList(currentTabsList);

        handleTabClick?.(selectedTabId);

        if (href) {
            navigate(href);
        }
    }

    return (
        <div>
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabsList.map((tab) => (
                    <span
                        key={tab.name}
                        className={classNames(
                            'whitespace-nowrap border-b-2 pt-3 pb-4 px-1 text-sm font-medium hover:cursor-pointer',
                            tab.current ? 'text-primary-color border-b border-primary-color' : 'hover:border-gray-300',
                            !tab.current && 'text-gray-500'
                        )}
                        onClick={() => onTabClick(tab.id, tab.href)}
                    >
                        {tab.name}
                        {tab.selectedItemsCount !== undefined &&
                            <span
                                className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1 text-xs font-medium text-gray-500 ml-[10px]"
                            >
                                {tab.selectedItemsCount}
                            </span>}
                    </span>
                ))}
            </nav>
        </div>
    )


}

export default Tabs;
