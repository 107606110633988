import { useState } from "react";
import { PASSWORD_POLICY, PASSWORDS_DO_NOT_MATCH_ERROR } from "../../../../constants/messages";
import { OnboardingUserDetailsI } from "../../../../types/onboarding";
import { validatePassword } from "../../../../utils/validationHelpers";
import PasswordField from "../../../../components/PasswordField/PasswordField";
import { Auth } from "aws-amplify";

interface LeftComponentI {
    onboardingUserDetails: OnboardingUserDetailsI,
    setOnboardingUserDetails: (onboardinUserDetails: OnboardingUserDetailsI) => void,
    handleGotoNextStep: () => void
}

const LeftComponent = (props: LeftComponentI) => {

    const { onboardingUserDetails, setOnboardingUserDetails, handleGotoNextStep } = props;

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [invalidPasswordErrorMsg, setInvalidPassswordErrorMsg] = useState<string>('');
    const [isPasswordsNotMatching, setIsPasswordsNotMatching] = useState<boolean>(false);

    const handlePasswordChange = (value: string) => {
        setInvalidPassswordErrorMsg('');
        setIsPasswordsNotMatching(false);

        const updatedCurrentUser: OnboardingUserDetailsI = {
            ...onboardingUserDetails,
            user: {
                ...onboardingUserDetails.user,
                password: value
            }
        }
        setOnboardingUserDetails(updatedCurrentUser);
    }

    const handleConfirmPasswordChange = (value: string) => {
        setIsPasswordsNotMatching(false);
        setConfirmPassword(value);
    }

    const handleNextButtonClick = async () => {
        const passwordError = validatePassword(onboardingUserDetails.user.password);
        if (passwordError) {
            setInvalidPassswordErrorMsg(passwordError);
        } else if (onboardingUserDetails.user.password !== confirmPassword) {
            setIsPasswordsNotMatching(true);
        } else {
            try {
                await Auth.signUp({
                    username: onboardingUserDetails.user.email,
                    password: onboardingUserDetails.user.password,
                    attributes: {
                        email: onboardingUserDetails.user.email,
                        given_name: onboardingUserDetails.user.given_name,
                        family_name: onboardingUserDetails.user.family_name,
                    },
                });
                handleGotoNextStep();
            } catch (error: any) {
                console.log(error);
                if (error.message === 'An account with the given email already exists.') {
                    await Auth.resendSignUp(onboardingUserDetails.user.email);
                    handleGotoNextStep();
                }
            }
        }
    }

    return (
        <div className="flex flex-col gap-6 mt-10">
            <div className="text-3xl font-medium text-slate-600">Set your password</div>
            <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-4 sm:w-[400px] w-full">
                    <div className='flex flex-col gap-2'>
                        <label className='text-slate-600 font-semibold text-sm'>Password</label>
                        <PasswordField password={onboardingUserDetails.user.password} handlePasswordChange={handlePasswordChange} />
                        {invalidPasswordErrorMsg && <div className='text-rose-500 text-sm'>{invalidPasswordErrorMsg}</div>}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-slate-600 font-semibold text-sm'>Confirm password</label>
                        <PasswordField password={confirmPassword} handlePasswordChange={handleConfirmPasswordChange} />
                        {isPasswordsNotMatching && <div className='text-rose-500 text-sm'>{PASSWORDS_DO_NOT_MATCH_ERROR}</div>}
                    </div>
                    <div className="text-gray-400 text-xs">{PASSWORD_POLICY}</div>
                </div>
                <button
                    className="text-white text-sm font-medium bg-primary-color"
                    onClick={() => handleNextButtonClick()}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default LeftComponent;