import React from 'react';
import { classNames } from '../../utils/styleHelpers'
import { Fragment } from 'react'
import { Menu as ReactMenu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from 'lucide-react';
import { DropdownOptionI } from '../../types/ui/dropdowns';

interface DropdownMenuI {
  isDisabled?: boolean,
  label: string,
  options: DropdownOptionI[],
  onOptionClick: (value: string | number, label: string) => void
}

const Dropdown = (props: DropdownMenuI) => {

  const { isDisabled = false, label, options, onOptionClick } = props;

  return (
    <ReactMenu as="div" className="relative w-full text-left">
      <ReactMenu.Button disabled={isDisabled} className={classNames("inline-flex w-full items-center justify-between sm:text-sm sm:leading-6 rounded-md bg-white px-3 py-2 text-sm text-slate-600 ring-1 ring-inset ring-gray-200", isDisabled && 'opacity-40')}>
        {label}
        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
      </ReactMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <ReactMenu.Items className="absolute z-10 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((opt: DropdownOptionI, index) =>
              <ReactMenu.Item key={index}>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-2 sm:px-4 py-2 text-sm', opt.stylingClasses ? opt.stylingClasses : ''
                    )}
                    onClick={(e) => onOptionClick(opt.value, opt.label)}
                  >
                    {opt.label}
                  </span>
                )}
              </ReactMenu.Item>
            )}
          </div>
        </ReactMenu.Items>
      </Transition>
    </ReactMenu>
  )
}

export default Dropdown;
